import Logo from "../../images/Home_360_logo-darkbckgrnd copy.svg";

const Footer = () => {
  return (
    <footer className="flex justify-between   flex-col md:flex-row   items-start px-[40px] pb-[100px] pt-[40px]">
      <div className="flex flex-wrap items-start gap-10">
        <div className="company-info text-left gap-2 flex flex-col">
          <div>
            <h3>APTOS INNOVATIONS PVT. LTD.</h3>
          </div>
          <div>
            <p>FP-27/1, Off Bhayli Canal Road</p>
            <p>Near Anna 48, Vadodara</p>
            <p>391410, Gujarat, INDIA</p>
          </div>
        </div>
        <div className="contact text-left">
          <h3>CONTACT US</h3>
          <a href="mailto:mail@aptosinnovations.com">
            mail@aptosinnovations.com
          </a>
        </div>
      </div>
      <div className="logo">
        <img src={Logo} alt="Home360" />
      </div>
    </footer>
  );
};

export default Footer;
