import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../../styles/Navbar.css";
import Logo from "../../images/Home_360_logo-darkbckgrnd copy.svg";
import LoginForm from "../Login/LoginForm";
// import logo from '../../assets/logo.png'; // Make sure to add your logo image

const Navbar = () => {
  const navigate = useNavigate();

  const [isOpen, setIsOpen] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    // Check for user data in localStorage on component mount
    const storedUserData = localStorage.getItem("userData");
    if (storedUserData) {
      setUserData(JSON.parse(storedUserData));
      setIsLoggedIn(true);
    }
  }, []);

  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);

  return (
    <header className="header">
      <div className="logo" onClick={()=>{
        navigate("/")
      }}>
        <img src={Logo} alt="Home360" />
      </div>
      {/* {!isLoggedIn ? (
        <div className="header-logo bg-[white]">
          <button onClick={openModal}>
            <a className="login-link text-[#DB6312]">
              log in
            </a>
          </button>
        </div>
      ) : (
        <div className=" flex items-end gap-4">
          <Link to="/manage-account" className="nav-link hover:underline hover:decoration-[#DB6312]">
            Manage my account
          </Link>
          <div className="user-profile flex flex-col justify-center items-center gap-2">
            <div className="avatar w-8 h-8 bg-gray-100 rounded-full flex items-center justify-center">
              <svg className="w-4 h-4 text-gray-500" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12 12C14.2091 12 16 10.2091 16 8C16 5.79086 14.2091 4 12 4C9.79086 4 8 5.79086 8 8C8 10.2091 9.79086 12 12 12Z" fill="currentColor"/>
                <path d="M12 14C8.13401 14 5 17.134 5 21H19C19 17.134 15.866 14 12 14Z" fill="currentColor"/>
              </svg>
            </div>
            <span className="username text-white">
              {userData?.firstName} {userData?.lastName}
            </span>
          </div>
        </div>
      )} */}

      {/* Modal */}
      {isOpen && <LoginForm closeModal={closeModal} />}
    </header>
  );
};

export default Navbar;
