import logo from "./logo.svg";
import "./App.css";
import LoginForm from "./components/Login/LoginForm";
import { Route, Routes } from "react-router-dom";
import Home from "./components/Task/Home";
import HomePage from "./components/HomePage/HomePage";
import AddTask from "./components/Task/AddTask";
import Navbar from "./components/Navbar/Navbar";
import Footer from "./components/Footer/Footer";

function App() {
  return (
    <div className="App">
      <Navbar />
      <Routes>
        {/* <Route path="/login" element={<LoginForm />} /> */}
        <Route path="/" element={<HomePage />} />
        {/* <Route path="/home" element={<Home />} />
      <Route path="/add-tasks" element={<AddTask />} /> */}
      </Routes>

      <Footer />
    </div>
  );
}

export default App;
